// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nesis-body-jsx": () => import("./../../../src/pages/nesis/body.jsx" /* webpackChunkName: "component---src-pages-nesis-body-jsx" */),
  "component---src-pages-nesis-header-jsx": () => import("./../../../src/pages/nesis/header.jsx" /* webpackChunkName: "component---src-pages-nesis-header-jsx" */),
  "component---src-pages-nesis-index-js": () => import("./../../../src/pages/nesis/index.js" /* webpackChunkName: "component---src-pages-nesis-index-js" */),
  "component---src-pages-nesis-qanda-jsx": () => import("./../../../src/pages/nesis/qanda.jsx" /* webpackChunkName: "component---src-pages-nesis-qanda-jsx" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-mariadb-js": () => import("./../../../src/pages/products/mariadb.js" /* webpackChunkName: "component---src-pages-products-mariadb-js" */),
  "component---src-pages-products-mysql-js": () => import("./../../../src/pages/products/mysql.js" /* webpackChunkName: "component---src-pages-products-mysql-js" */),
  "component---src-pages-products-postgresql-js": () => import("./../../../src/pages/products/postgresql.js" /* webpackChunkName: "component---src-pages-products-postgresql-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

